.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mild-neumorphic-card {
  box-shadow: 5px 5px 12px rgba(0, 0, 30, 0.5), 
            -5px -5px 10px rgba(255, 255, 255, 0.7);
}


.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #b4b3b3;
  background: linear-gradient(to right, #d1d1d1 8%, #adadad 18%, #c0c0c0 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}


.primary-color {
  color: rgb(1, 151, 221);
}


.dropdown-item {
  background-color: white;
  color: black;
}

.dropdown-item:hover {
  background-color: rgb(1, 151, 221);
  color: white;
}